.captcha-container {
    width: 95%;
    background-color: #222;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    max-width: 900px;
    margin: 0 auto;
    padding: 40px 20px;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.captcha-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 20px;
}

.captcha-site {
    font-size: 24px;
    font-weight: 500;
    font-weight: bold;
}

.captcha-title {
    text-align: start;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
    /* font-weight: bold; */
}

.captcha-box {
    max-width: 300px;
    border: 1px solid #444;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    min-height: 70px;
    justify-content: center;
}

.captcha-checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.captcha-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.captcha-box-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    /* min-width: 290px; */
}

.captcha-checkbox-custom {
    position: relative;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 2px solid #aaa;
    border-radius: 2px;
    margin-right: 10px;
}

.captcha-checkbox:checked~.captcha-checkbox-custom {
    background-color: #4caf50;
    border-color: #4caf50;
}

.captcha-checkbox-custom:after {
    content: "";
    position: absolute;
    display: none;
}

.captcha-checkbox:checked~.captcha-checkbox-custom:after {
    display: block;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.captcha-text {
    font-size: 14px;
}

.captcha-provider {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex;
    margin-top: 10px;
}

.captcha-provider-logo {
    width: 73px;
    /* height: 73px; */
}

.captcha-provider-logo-container {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.captcha-provider-logo-text {
    width: 100%;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    margin-top: -5px;
    text-align: end;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
}

.captcha-provider-text {
    font-size: 12px;
    color: #aaa;
    /* margin-top: 5px; */
    text-align: end;
    text-decoration: underline;
    cursor: pointer;
}

.captcha-info {
    font-size: 16px;
    text-align: start;
}

.captcha-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.captcha-verified {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #4caf50;
}


.captcha-spinner {
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

.captcha-installing-image {
    width: 32px;
    height: 32px;
}

.captcha-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
}