
.arrow-install {
    position: fixed;
    top: 66%;
    left: 80%;
    z-index: 999;
    /* width: 100vw;
    height: 100vh; */
}

.arrow-install-image {
    position: fixed;
    /* top: 75%;
    left: 85%; */
    z-index: 999;
    animation: bounce 1s infinite;
    animation-timing-function: ease-in-out;
    transform: scale(1.5);

}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}


.arrow-install-desktop {
    position: fixed;
    top: 20%;
    left: 56%;
    z-index: 999;
    /* width: 100vw;
    height: 100vh; */
}