.videoContainer {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.video {
    width: 100%;
    transition: filter 0.3s ease;
}

.blurred {
    filter: blur(10px);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    padding: 20px;
}

.overlayContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.installButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.installButton:hover {
    background-color: #0056b3;
}

.actionButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.actionButton:hover {
    background-color: #0056b3;
}

.overlayContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    text-align: center;
    max-width: 80%;
}

.customControls {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.customControls button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.customControls button:hover {
    background-color: #0056b3;
}

@keyframes jump {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.arrow1 {
    position: absolute;
    top: calc(50% + 50px);
    left: 50%;
    transform: translate(-50%, -50%);
    animation: jump 1s infinite;
}


.arrow2 {
    position: absolute;
    top: 25%;
    left: calc(50% + 20vw);
    transform: translate(-50%, -50%);
    animation: jump 1s infinite;
}

.arrowDesktop {
    position: fixed;
    top: 17vh;
    left: calc(50vw + 120px);
    transform: translate(-50%, -50%);
    animation: jump 1s infinite;
}
